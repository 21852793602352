import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import cognitoAuth from './cognito'
import vuetify from '@/plugins/vuetify'
import moment from 'moment'

Vue.config.productionTip = false

Vue.filter('shortDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM Do')
  }
});

Vue.use(VueGtag, {
  config: { id: 'UA-3455075-4' },
});

new Vue({
  vuetify,
  router,
  cognitoAuth,
  render: h => h(App)
}).$mount('#app')
