import axios from 'axios'
import cognitoAuth from '@/cognito'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  json: true
})

export default {
  async execute (method, resource, data, authRequired) {
    let token = ''

    if (authRequired) {
      cognitoAuth.getIdToken((err, session) => {
        if (err) console.log(err)
        if (session) token = session
      })
    }
      
    return client({
      method,
      url: resource,
      headers: {
        'Authorization': token
      },
      data
    }).then(req => {
      return req.data
    })
  },
  delete(resource) {
    return this.execute('delete', resource, null, true)
  },
  get(resource) {
    return this.execute('get', resource, false)
  },
  post(resource, data) {
    return this.execute('post', resource, data, true)
  }
}