<template>
  <div id="report-card">
    <div class="report-img" :style="{ backgroundImage: 'url(' + require('@/assets/'+src+'.jpg') + ')' }">
    </div>
    <div class="report-text">
      <div class="rating-flow">
        <div class="trip-report-card__content-rating">
          <i class="material-icons" v-for="item in this.getFullStars(rating)" :key="item+'noborder'" aria-label="icon star rating">star_rate</i>
          <i class="material-icons" v-for="item in this.getEmptyStars(rating)" :key="item+'border'" aria-label="icon star border">star_border</i>
        </div>
        <p>Flow: {{flow}}</p>
      </div>
      <h3>{{name}}</h3>
      <div class="date-seemore">
        <p>{{date}}</p>
        <router-link class="see-more" :to="`/reports/${id}`">See More &gt; </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import locations from '../data/destinations.json'
export default {
  name: 'ReportCard',
  props:  {
    id: String, 
    name: String, 
    date: String,
    flow: String,
    rating: String, 
    drainage: String
  }, 
  data() {
    return {
      src: this.getImgSrc()
    };
  },
  methods: {
    getFullStars(value) {
      return [...Array(parseInt(value)).keys()];
    },
    getEmptyStars(value) {
      return [...Array(Math.abs(parseInt(value) - 5)).keys()]
    },
    getImgSrc() {
      if (!this.drainage) return 'front_range'
      let destination = locations.find(c => c.name == this.drainage)['rivers']
      let location = destination.find(r => r.name === this.name)
      return location['src']
    }
  }
}
</script>

<style scoped lang="scss">
#report-card {
  border: 3px solid black;
  margin: 20px 0px;
  .report-img {
    height: 300px;
    background-size: cover;
    background-position: center;
  }
  .rating-flow, .date-seemore {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .report-text {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    flex-direction: column;
    text-align: left;
  }
  .material-icons {
    color: #3C6E71
  }
}
</style>
