import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import cognitoAuth from '@/cognito'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/hatch-chart',
    name: 'HatchChart',
    component: () => import('../views/HatchChart.vue')
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports.vue')
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: () => import('../views/Report.vue')
  },
  {
    path: '/destinations',
    name: 'Destinations',
    component: () => import('../views/Destinations.vue')
  },
  {
    path: '/destinations/:id/',
    name: 'Rivers',
    component: () => import('../views/Rivers.vue'),
  },
  {
    path: '/destinations/:id/:river_id',
    name: 'Destination',
    component: () => import('../views/Destination.vue'),
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/Resources.vue')
  },
  {
    path: '/co-fly-fishing-blog',
    beforeEnter() { location.href = 'http://blog.coloradoflyfishingreports.com' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/report/new',
    name: 'New Report',
    component: () => import('../views/NewReport.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let currentUser = cognitoAuth.getCurrentUser()
    if (!currentUser) { 
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  }
  next()
})

export default router
