<template>
  <div id="footer">
    <p class="copyright">&#169; {{year}} Colorado Fly Fishing Reports, LLC</p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
    data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style scoped lang="scss">
#footer {
  width: 100%;
}
</style>
