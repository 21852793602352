<template>
  <div id="home">
    <Hero/>
    <section>
    <div class="main-panel">
      <h1>Our Destinations</h1>
      <div class="destination" v-for="destination in destinations" :key="destination.id">
        <PropertyCardLg :name="destination.name" :url="destination.url" :src="destination.src" :rivers="destination.rivers"/>
      </div>
      <h1 class="report-title">Recent Reports</h1>
      <div class="reports">
        <div class="report" v-for="report in reports" :key="report.report_id">
          <ReportCard :name="report.location_name" :flow="report.flow" :id="report.report_id" :date="report.date" :rating="report.rating" :drainage="report.drainage_name"/>
        </div>
      </div>
      <router-link class="see-more" to="/reports">See More > </router-link>
    </div>
    <div class="side-panel">
      <FollowUs/>
      <BookNow/>
    </div>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import Hero from '@/components/Hero.vue'
import PropertyCardLg from '@/components/PropertyCardLg.vue'
import ReportCard from '@/components/ReportCard.vue'
import FollowUs from '@/components/FollowUs.vue'
import BookNow from '@/components/BookNow.vue'
import destinations from '../data/destinations.json'
export default {
  name: 'Home',
  components: {
    Hero, 
    PropertyCardLg, 
    ReportCard, 
    FollowUs, 
    BookNow
  },
  data() {
    return {
      destinations: destinations,
      reports: [],
    };
  },
  async created () {
    const getReports = await api.get('/reports')
    this.reports = this.sort(getReports.reports)
  },
  methods: {
    sort(reports) {
      return reports.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  }
}
</script>

<style lang="scss">
#home {
  h1 {
    text-align: left;
  }
  section {
    display: flex;
  }
  .main-panel {
    width: 70%;
    padding: 50px;
    border-right: 3px solid black;
  }
  .side-panel {
    width: 30%;
  }
  .report-title {
    margin-top: 50px;
  }
  .reports {
    display: flex; 
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .report {
    width: 30%;
    min-width: 300px;
  }
  @media only screen and (max-width: 700px) {
    section {
      flex-direction: column;
      margin: auto;
      width: 90%;
    }
    .main-panel {
      width: 100%;
      border-right: none;
      padding: 0;
    }
    .side-panel {
      width: 100%;
    }
  }
  @media only screen and (max-width: 500px) {
    margin: auto;
    .report, .destination {
      width: 100%;
      margin: auto;
    }
  }
}
</style>