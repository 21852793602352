<template>
  <div id="book-now">
    <h2>Fish With Us</h2>
    <div class="book-now-buttons">
      <div class="logo-button">
        <img src="../assets/ctf-logo.png">
        <a id="to-ctf-homepage" target="_blank" v-bind:href="`https://www.coloradotroutfisher.com/book-a-trip/`">Public Water Trips</a>
      </div>
      <div class="logo-button">
        <img src="../assets/au-logo-black.png">
        <a id="to-au-homepage" target="_blank" v-bind:href="`https://anglinguniversity.com/`">Learn to Fly Fish</a>
      </div>
      <div class="logo-button">
        <img src="../assets/ffgs-logo.png">
        <a id="to-ffgs-homepage" target="_blank" v-bind:href="`https://www.theflyfisher.com/`">Private Water Trips</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookNow',
}
</script>

<style scoped lang="scss">
#book-now {
  width: 65%;
  margin: auto;
  padding-bottom: 50px;
  h2 {
    text-align: left;
    margin-top: 50px;
  }
  img {
    max-width: 250px;
  }
  a {
    border: 3px solid black;
    padding: 10px;
    background-color: white;
    margin-top: 20px;
  }
  .book-now-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  .logo-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    max-width: 250px;
    margin: 20px;
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
  .book-now-buttons {
    justify-content: center;
  }
  .logo-button {
    width: 100%;
  }
}
</style>
