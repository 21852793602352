<template>
  <div id="hero">
    <h1>Colorado Fly Fishing Reports</h1>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style scoped lang="scss">
#hero {
  background-image: url('~@/assets/hero.jpeg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
    font-size: 5rem;
  }
  @media only screen and (max-width: 600px) {
    height: 250px;
    h1 {
      font-size: 3rem;
    }
  }
}
</style>
