<template>
  <div id="app">
    <Nav/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',
  components: {
    Nav, 
    Footer
  }
}
</script>

<style lang="scss">
// font-family: 'Lato', sans-serif;
// font-family: 'Raleway', sans-serif;
// Teal: #3C6E71;
html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: black;
}
a {
  text-decoration: none;
}
a, a:visited, a:hover, a:active {
  color: inherit;
}
.material-icons {
  color: white;
}
.material-icons:hover {
  cursor: pointer;
}
h1, h2, h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
a, p, button, li, label {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
}
a.see-more {
  color: #3C6E71;
  font-weight: 600;
  font-size: 18px;
}
button, input {
  outline: none;
}
</style>
