<template>
  <div id="follow-us">
    <div class="follow-header">
      <h2>Follow Us</h2>
      <div class="social-icons">
        <a class="instagram" target="_blank" v-bind:href="`https://www.instagram.com/coloradotroutfisher/`"> </a>
        <a target="_blank" v-bind:href="`https://www.facebook.com/ColoradoTroutFisher`"><i class="material-icons">facebook</i></a>
      </div>
    </div>
    <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
  </div>
</template>

<script>
export default {
  name: 'FollowUs',
  methods: {
    getCurator () {
      var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
      i.src = "https://cdn.curator.io/published/560b6479-b80f-4a98-9bd7-1c264bdef987.js";
      e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
    }
  },
  created() {
    this.getCurator()
  }
}
</script>

<style scoped lang="scss">
#follow-us {
    width: 65%;
    margin: auto;
  .follow-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .social-icons {
    display: flex;
  }
  .instagram {
    display: block;
    background-image: url('~@/assets/instagram.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
  }
  i {
    color: black;
    padding-left: 10px;
    font-size: 30px;
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
}
</style>
