<template>
  <router-link :to="url">
    <div id="property-card-large">
      <div class="property-img" :style="{ backgroundImage: 'url(' + require('@/assets/'+src+'.jpg') + ')' }">
      </div>
      <div class="property-text">
      <div>
        <h3>{{name}}</h3>
      </div>
      <router-link class="see-more" :to="url">See More &gt;</router-link>
      </div>
      <div class="rivers">
        <p v-for="river in rivers" :key="river.river_id">{{river.name}}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PropertyCardLg',
  props:  {
    name: String, 
    url: String, 
    src: String, 
    rivers: Array
  }
}
</script>

<style scoped lang="scss">
#property-card-large {
  border: 3px solid black;
  margin: 20px 0px;
  .property-img {
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .property-text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
  }
  .rivers {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
}
</style>
