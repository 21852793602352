<template>
  <div class="not-found">
    <h2 class="error">404</h2>
    <p class="gone-fishing">We're having a reel-y hard time finding this page!</p>
    <BookNow/>
  </div>
</template>

<script>
import BookNow from '@/components/BookNow.vue'
export default {
  name: 'NotFound',
    components: { 
      BookNow
  }
}
</script>

<style lang="scss">
.error {
  font-size: 150px;
  margin-bottom: 30px;
}
.gone-fishing {
  font-size: 30px;
  margin-bottom: 100px;
}
</style>