<template>
  <div id="nav">
    <a href="javascript:void(0);" class="mobile" v-on:click="expandMenu()">
      <i class="material-icons">menu</i>
    </a>
    <ul id="menu" class="links">
      <li v-on:click="collapseMenu"><router-link to="/">Home</router-link></li>
      <li v-on:click="collapseMenu"><router-link to="/co-fly-fishing-blog">Blog</router-link></li>          
      <li v-on:click="collapseMenu"><router-link to="/reports">Reports</router-link></li>
      <li v-on:click="collapseMenu" class="dropdown">
        <router-link to="/destinations">Destinations</router-link>
        <ul>
          <li v-for="destination in destinations" :key="destination.id">
            <router-link :to="destination.url">{{destination.name}}</router-link>
            <ul>
              <li v-for="river in destination.rivers" :key="river.id"><router-link :to="river.url">{{river.name}}</router-link></li>
            </ul>
          </li>
        </ul>
      </li>
      <li v-on:click="collapseMenu"><router-link to="/hatch-chart">Hatch Chart</router-link></li>
      <li v-on:click="collapseMenu"><router-link to="/resources">Resources</router-link></li>
    </ul>
  </div>
</template>

<script>
import destinations from '../data/destinations.json'
export default {
  name: 'Nav',
    data() {
      return {
        destinations: destinations      };
    },
    methods: {
      expandMenu() {
        let x = document.getElementById("menu");
      if (x.className === "links") {
        x.className += " responsive";
      } else {
        x.className = "links";
      }
    },
    collapseMenu() {
      let x = document.getElementById("menu");
      if (x.className === "links responsive") {
        x.classList.remove("responsive");
      }
    }
  }       
}
</script>

<style scoped lang="scss">
#nav {
  padding: 30px;
  background-color: black;
  color: white;
  .links {
    display: flex;
    justify-content: space-between;
    margin: auto;
    list-style-type: none;
  }
  .mobile {
    display: none;
  }
  a {
    &.router-link-exact-active {
      color: #3C6E71;
    }
  }
  ul li {
    display: block;
    position: relative;
    float: left;
  }
  li ul { 
    display: none; 
  }
  ul li a {
    display: block;
    padding: 1em;
    text-decoration: none;
    white-space: nowrap;
    color: #fff;
  }
  li:hover > ul {
    display: block;
    position: absolute;
  }
  li:hover li { 
    float: none; 
  }
  li:hover a { 
    background-color: black;
  }
  li:hover li a:hover { 
    background: #3C6E71; 
    color: white;
  }
 .links li ul li { 
   border-top: 0; 
  }
  ul ul ul {
    left: 98%;
    top: 0;
  }
  ul:before, ul:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  ul:after { 
    clear: both; 
  } 
  @media only screen and (max-width: 900px) {
    .mobile {
      display: block;
      float: right;
    }
    .links {
      display: none;
    }
    li:hover > ul {
      display: none;
    }
    .responsive {
      height: 450px;
      padding: 30px 0 20px 0;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
